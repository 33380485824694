<template>
  <div>
    <el-dialog :visible.sync="editShow" width="70%" title="共享空间" :close-on-click-modal="false">
      <el-form :model="form" label-width="auto" :rules="rules" ref="form">
        <div class="flex">
          <div class="flex-1">
            <el-form-item label="类型" required>
              <el-radio-group v-model="form.type">
                <el-radio label="shared_space">共享空间</el-radio>
                <el-radio label="venue">共享场馆</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="场馆名称" required prop="name">
              <el-input
                v-model="form.name"
                placeholder="场馆名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="共享类目">
              <el-radio-group
                v-model="form.categories"
                @change="changeCateType"
              >
                <el-radio label="体育运动">体育运动</el-radio>
                <el-radio label="休闲生活">休闲生活</el-radio>
                <el-radio label="夜校">夜校</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="选择类目" required key="cate">
              <div @click="cateShow = true">
                {{ form.categories_child || "选择子类目" }}
              </div>
            </el-form-item>
            <el-form-item label="省市区" required key="city">
              <city-select @change="changeCity" :temp="cityList"></city-select>
            </el-form-item>
            <el-form-item label="详细地址" required prop="address">
              <el-input v-model="form.address">
                <addr-select
                  slot="append"
                  @getform="addMecAddr"
                  type="icon"
                ></addr-select>
              </el-input>
            </el-form-item>
            <el-form-item label="经度" prop="longitude">
              <el-input v-model="form.longitude" placeholder="经度"></el-input>
            </el-form-item>
            <el-form-item label="纬度" prop="latitude">
              <el-input v-model="form.latitude" placeholder="纬度"></el-input>
            </el-form-item>
            <el-form-item
              label="机构电话"
              required
              prop="telephone"
              key="telephone"
            >
              <el-input
                v-model="form.telephone"
                placeholder="机构电话，用户联系"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="负责人姓名"
              required
              key="contacts"
            >
              <el-input v-model="form.contacts" placeholder="负责人"></el-input>
            </el-form-item>
            <el-form-item
              label="负责人电话"
              required
              key="contact_telephone"
            >
              <el-input
                v-model="form.contact_telephone"
                placeholder="登录账号"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="密码"
            >
              <el-input
                v-model="form.login_pass"
                placeholder="密码，不填默认为电话"
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex-1">
             <el-form-item
              label="营业时间"
            >
              <el-input
                v-model="form.opentime"
                placeholder="周一至周日,09:00-20:00"
              ></el-input>
            </el-form-item>
            <el-form-item label="场地到期时间">
              <el-date-picker
                v-model="form.expired_time"
                type="date"
                value-format="yyyy-MM-dd 00:00:00"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item label="简介">
              <el-input
                type="textarea"
                v-model="form.introduction_content"
              ></el-input>
            </el-form-item>
            <el-form-item label="机构logo">
              <avatar-uploader
                @getImgUrl="(v) => (form.logo = v)"
                :url="temp.logo"
                :clear="if_clear"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item label="营业执照" ref="support">
              <images-uploader
                @getImgUrl="(v) => (form.support_means = v)"
                :urls="temp.support_means"
                :clear="if_clear"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="机构资质">
              <images-uploader
                @getImgUrl="(v) => (form.qualifications_pic = v)"
                :urls="temp.qualifications_pic"
                :clear="if_clear"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="简介图片">
              <images-uploader
                @getImgUrl="(v) => (form.introduction_pic = v)"
                :urls="temp.introduction_pic"
                :clear="if_clear"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="机构门面图">
              <images-uploader
                @getImgUrl="(v) => (form.facade_view = v)"
                :urls="temp.facade_view"
                :clear="if_clear"
              >
              </images-uploader>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button v-if="form.id" type="primary" @click="updateForm"
          >修 改</el-button
        >
        <el-button v-else type="primary" @click="submitForm">添 加</el-button>
      </span>
      <el-dialog
        title="选择类目"
        :visible.sync="cateShow"
        width="60%"
        append-to-body
      >
        <el-transfer
          filterable
          filter-placeholder="请选择绑定的科目"
          v-model="changeCates"
          :data="categories_child_list"
          :props="{
            key: 'name',
            label: 'name',
          }"
          :titles="['所有类目', '拥有类目']"
        >
        </el-transfer>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cateShow = false">取 消</el-button>
          <el-button type="primary" @click="confirmCategory">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>
  
  <script>
import { addRule } from "./addRule";
import { ObjectChangedValue } from "@/js/utils";
import cooperatorSelect from "@/components/select/cooperatorSelect.vue";
export default {
  components: {
    cooperatorSelect,
  },
  data() {
    return {
      form: {
        categories: "体育运动",
        operate: "settle",
        type: "teach_paypal",
      },
      temp: {},
      rules: addRule,
      sub_mec: false,
      coop_id: "",
      editShow: false,
      categories_child_list: [],
      select_mechanism: "",
      mechanism_name_list: [],
      venues_list: "",
      if_clear: true,
      count: 1,
      cityList: [],
      if_admin: false,
      cateShow: false,
      changeCates: [],
    };
  },
  mounted() {},

  methods: {
    confirmCategory() {
      this.form.categories_child = this.changeCates.join(",");
      this.cateShow = false;
    },
    changeCateType() {
      this.changeCates = [];
      this.getCateList();
    },
    getCateList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      let data = {
        status: 2,
        type: 2,
        source: "space",
      };
      if (this.form.categories != "体育运动") {
        data.source = this.form.categories;
      }

      this.$axios
        .get(url, {
          params: data,
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
    // 新增机构地址
    addMecAddr(addr) {
      if (addr.address) {
        this.$set(this.form, "province", addr.province);
        this.$set(this.form, "city", addr.city);
        this.$set(this.form, "district", addr.district);
        this.$set(this.form, "address", addr.address);
        this.cityList = [];
        this.cityList.push(addr.province);
        this.cityList.push(addr.city);
        this.cityList.push(addr.district);
      }

      this.$set(this.form, "latitude", addr.addrPoint.lat);
      this.$set(this.form, "longitude", addr.addrPoint.lng);
    },
    changeCity(v) {
      this.cityList = v;
      this.form.province = v[0];
      this.form.city = v[1];
      this.form.district = v[2];
    },
    
    editDataInfo() {},
    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.cityList = [];
        this.cityList.push(info.province);
        this.cityList.push(info.city);
        this.cityList.push(info.district);
        this.temp.data = { ...this.form };
        this.sub_mec = info.parent_id > 0;
        this.coop_id = info.cooperator_id;
        if (info.categories_child) {
          this.changeCates = info.categories_child.split("/");
        } else {
          this.changeCates = [];
        }

        if (info.cooperator_id > 0) {
          this.coopPlaceholder = info.map.cooperator_name;
        }
        if (info.parent_id > 0) {
          this.mecPlaceholder = info.map.mechanism_name;
        }
      } else {
        this.if_clear = !this.if_clear;
        this.form = Object.assign(
          { categories: "体育运动",  type: "shared_space",opentime:'周一至周日,09:00-20:00' },
          info
        );
        this.temp = {};
      }
      this.getCateList();
      this.editShow = true;
    },
    updateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updateData();
        }
      });
    },
    submitForm() {
      if (!this.form.support_means) {
        this.$message("请上传营业执照");
        return;
      }
      if (!this.form.logo) {
        this.$message("logo");
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.insertData();
        }
      });
    },
    insertData() {
      let url = "/shared/sharedSpace/register";
      this.form.status = 1;
    
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios
        .post(url, this.form)
        .then((res) => {
          loading.close();
          this.$message({ type: "warning", message: "添加成功!" });
          this.editShow = false;
          this.form = {};
          this.$emit("success");
        })
        .catch((err) => {
          loading.close();
        });
    },
    updateData() {
    
      let data = ObjectChangedValue(this.temp.data, this.form);
      data.id = this.form.id;

      let url = "/shared/sharedSpace/update";
      const loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$axios
        .post(url, data)
        .then((res) => {
          loading.close();
          this.$message({ type: "success", message: "修改成功!" });
          this.editShow = false;
          this.form = {};
          this.temp = {};
          this.$emit("success");
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 机构查询远程查询
    searchOrgName(query) {
      if (query && typeof query == "string") {
        let url = "/user/mastermechanism/queryByMessage";
        let data = {
          type: this.form.type,
          pageSize: 50,
          currentPage: 1,
          status: 2,
          parent_id:0
        };
        const numberReg = /^\d/;
        numberReg.test(query)
          ? (data.mechanism_telephone = query)
          : (data.mechanism_name = query);
        this.$axios
          .get(url, {
            params: data,
          })
          .then((res) => {
            this.mechanism_name_list = res.data.data.rows;
          })
          .catch((err) => {});
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>